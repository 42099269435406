import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/logo/Logo_RoseGold.png";
import { Search, List, ChevronLeft } from "react-bootstrap-icons";

export default function NavigationBar() {
  const side_nav = useRef();
  const search = useRef();

  let menu_list = [
    {
      name: "Home",
      route: "/",
    },
    {
      name: "Our Story",
      route: "/our-story",
    },
    {
      name: "Services",
      route: "/service",
    },
    {
      name: "Blog",
      route: "/blog",
    },
    {
      name: "Package",
      route: "/package",
    },
    {
      name: "Experience",
      route: "/experience",
    },
    {
      name: "Gallery",
      route: "gallery",
    },
  ];
  let link_list = [];
  menu_list.forEach((menu) => {
    link_list.push(
      <div className="nav-item" key={menu.name}>
        <NavLink
          to={menu.route}
          className={({ isActive }) => (isActive ? "active-route" : undefined)}
        >
          {menu.name}
        </NavLink>
      </div>
    );
  });
  function toggleSideNav() {
    side_nav.current.classList.toggle("active");
  }
  function toggleSearchInput() {
    //toggle active class on search input and wait for animation done then focus on it if it's active
    search.current.classList.toggle("active");
    setTimeout(() => {
      if (search.current.classList.contains("active")) {
        search.current.focus();
      }
    }, 300);
  }
  return (
    <div>
      <div className="nav">
        <div className="nav-collapse">
          <div className="nav-brand">
            <img src={logo} alt="" />
          </div>
          <div className="nav-list">
            <div className="nav-toggler">
              <List onClick={() => toggleSideNav()} />
            </div>
            <div className="nav-item-list">{link_list}</div>
            <div className="nav-search">
              {/* <Search
                onClick={toggleSearchInput}
                style={{ cursor: "pointer" }}
              /> */}
            </div>
          </div>
          {/* input search redirect to blog with query keyword on enter */}
          {/* <input
            className="search-input"
            type="text"
            placeholder="Search"
            ref={search}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                window.location.href = `/blog?keyword=${e.target.value}`;
              }
            }}
          /> */}
        </div>
      </div>
      <div id="side-nav" className="side-nav" ref={side_nav}>
        <div className="side-nav-brand">
          <img src={logo} alt="" />
        </div>
        <div className="side-nav-item-list">{link_list}</div>
        <div className="close-side-nav">
          <ChevronLeft onClick={() => toggleSideNav()} />
        </div>
      </div>
    </div>
  );
}
